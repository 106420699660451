import React, { useState } from 'react';
import style from './Home.module.scss';

import NavBar from "../../components/navbar/NavBarre";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import Presentation from "../../components/presentation/Presentation";
import Cours from "../../components/cours/Cours";
import New from "../../components/new/New";
import Events from "../../components/events/Events";

const Home = () => {
    return (
        <div className={style.home}>
            <NavBar />
            <Header />
            <Presentation />
            <New />
            <Cours />
            <Footer />
        </div>
    );
};

export default Home;