import React from 'react';
import style from './Footer.module.scss';
const Footer = () => {

    let date = new Date();
    const year = date.getFullYear();

    return (
        <footer id='contacter'>
            <div className={style.footerContainer}>
                <h2>Hypana</h2>
                <div className={style.footerHoraire}>
                    <h3>Horaires</h3>
                    <p>Lundi-Vendredi : 9h-18h</p>
                    <p>Samedi : 9h-12h</p>
                </div>
                <div className={style.footerContact}>
                    <h3>Contact</h3>
                    <p>Adresse : 827 Avenue de Juin 1940 38340 Voreppe</p>
                    <p>Téléphone : 06.10.64.69.48</p>
                    <p>Email : contact@hypana.fr</p>
                </div>
            </div>
            <p>© {year} Hypana. Tous droits réservés.</p>
        </footer>
    );
};

export default Footer;