import React from 'react';
import style from './Presentation.module.scss';
const Presentation = () => {
    return (
        <div>
            <section className={style.presentation}>
                <div className={style.presentationText}>
                    <h2>Anaïs Decloedt</h2>
                    <p>Orthoptiste, praticienne en hypnose et PNL, je suis aussi enseignante en Running Yoga et passionnée de trail.</p>
                    <p> Je vous propose des cours hebdomadaires et des ateliers pour venir optimiser vos performances en course à pied. Comment ?</p>
                    <p>En venant prendre conscience de votre souffle, du placement de vos épaules, de votre bassin, de vos appuis, de votre corps le tout en pratiquant le yoga ET la course à pied !</p>
                    <p>« Chacun possède les ressources nécessaires pour réaliser son objectif ».</p>
                </div>
                <div className={style.presentationImage}>
                    <img loading="lazy" src='./YOGA.webp' alt='Anais Decloedt' />
                </div>
            </section>
        </div>
    );
};

export default Presentation;