import React from 'react';
import style from './Header.module.scss';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/free-solid-svg-icons';
const Header = () => {
    
    const handleAnchorClick = (event, anchorId) => {
        event.preventDefault();
        const element = document.getElementById(anchorId);
        if (element) {
            element.scrollIntoView({behavior: 'smooth'});
        }
    };

    return (
        <div className={style.header} id='Accueil'>
        <div className={style.backgroundImage}></div>
    <div className={style.headerText}>
        <p><span>Hypana</span></p>
        <button><a href="#cours" onClick={(event) => handleAnchorClick(event, 'cours')}>En Savoir Plus</a></button>
    </div>
    <div className={style.scrollDownContainer} onClick={(event) => handleAnchorClick(event, 'cours')}>
        <div className={style.scrollDown}>
            <FontAwesomeIcon icon={faChevronDown}/>
        </div>
    </div>
</div>
    );
};

export default Header;