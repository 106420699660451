import React from 'react';
import style from './Cours.module.scss';
const Cours = () => {
    return (
        <div className={style.courseSection} id="cours">
            <h2>Running Yoga</h2>
            <div className={style.courseContainer}>
                <div className={style.courseCard}>
                    <h3>Inscription à l'année</h3>
                    <p>36 cours, Jeudi à 17h30</p>
                    <p>Prix: 320€ (étudiants 250€)</p>
                    <p>Payable en plusieurs fois</p>
                </div>
                <div className={style.courseCard}>
                    <h3>10 séances </h3>
                    <p>Prix: 130€ (tarif étudiant 70€)</p>
                    <p>Jeudi à 17h30</p>
                </div>
                <div className={style.courseCard}>
                    <h3>ateliers de 3h</h3>
                       <p>un samedi par mois</p>
                        <p>30€ l'atelier</p>
                </div>
            </div>
            <div className={style.contactInfo}>
                <p>Contactez Anaïs Decloedt pour un cours d'essai gratuit</p>
                <div className={style.buttonContainer}>
                    {/*telecharger le formulaire*/}
                    <button><a href="/Formulaire_inscription.pdf" target="_blank" rel="noopener noreferrer">Télécharger le formulaire</a></button>
                </div>
            </div>
        </div>
    );
};
export default Cours;