import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import style from './NavBarre.module.scss';
import { Link } from "react-router-dom";

const NavBarre = () => {
    const [isOpen, setIsOpen] = useState(false);  // Toggle pour le menu mobile

    return (
        <header>
            <nav className={style.wrapper}>
                <div className={style.logo}>
                    <Link to="/">Hypana</Link>
                </div>
                <FontAwesomeIcon
                    icon={isOpen ? faTimes : faBars}
                    className={style.menuToggle}
                    onClick={() => setIsOpen(!isOpen)}
                />
                <ul className={`${style.navLinks} ${isOpen ? style.open : ''}`}>
                    <li><Link to="/">Accueil</Link></li>
                    <li><a href="#cours">cours</a></li>
                    <li><a href="#new">Actualités</a></li>
                    <li><Link to="/enchainements">les enchainements</Link></li>
                </ul>
            </nav>
        </header>
    );
};

export default NavBarre;