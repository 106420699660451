import React, { useState, useEffect } from 'react';
import style from './Admin.module.scss';
import axios from 'axios';

const Admin = ({ newsItem = { title: "", imageUrl: "", text: "" }, setNews }) => {
    const [localNews, setLocalNews] = useState(newsItem);
    const [newsId, setNewsId] = useState(null); // Pour stocker l'ID de la nouvelle

    useEffect(() => {
        // Récupérer les nouvelles depuis la base de données lors du montage du composant
        axios.get('/news')
            .then(response => {
                if (response.data && response.data.length > 0) {
                    setLocalNews(response.data[0]); // Prendre la première nouvelle comme exemple
                    setNewsId(response.data[0].id);
                }
            })
            .catch(error => {
                console.error("Erreur lors de la récupération des nouvelles:", error);
            });
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setLocalNews(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = () => {
        if (newsId) {
            // Mettre à jour la nouvelle dans la base de données
            axios.put(`/news/${newsId}`, localNews)
                .then(response => {
                    console.log(response.data.message);
                    window.location.reload(); // Recharger la page après la mise à jour
                })
                .catch(error => {
                    console.error("Erreur lors de la mise à jour:", error);
                });
        } else {
            // Ajouter une nouvelle nouvelle si aucun ID n'est défini
            axios.post('/news', localNews)
                .then(response => {
                    setNewsId(response.data.id);
                    console.log("Nouvelle ajoutée avec succès:", response.data);
                    window.location.reload(); // Recharger la page après l'ajout
                })
                .catch(error => {
                    console.error("Erreur lors de l'ajout:", error);
                });
        }
    };


    return (
        <div className={style.Admin}>
            <div className={style.content}>
                <h1>Panneau d'administration</h1>
                <div className={style.formContainer}>
                    <label>
                        Titre:
                        <input type="text" name="title" value={localNews.title} onChange={handleChange} />
                    </label>
                    <label>
                        URL de l'image:
                        <input type="text" name="imageUrl" value={localNews.imageUrl} onChange={handleChange} />
                    </label>
                    <label>
                        Texte:
                        <textarea name="text" value={localNews.text} onChange={handleChange}></textarea>
                    </label>
                    <button onClick={handleSubmit}>Mettre à jour les nouvelles</button>
                </div>
            </div>
        </div>
    );
};

export default Admin;