import React from 'react';
import NavBar from "../../components/navbar/NavBarre";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import style from './Enchainements.module.scss';

const Enchainements = () => {
    return (
        <div>
            <NavBar />
            <Header />
            <h1>Enchainements</h1>
            <div className={style.flex}>
                <button><a href="./recup.pdf" target="_blank" rel="noopener noreferrer">Télécharger L'enchainement pour la récup</a></button>
                <button><a href="./dos.pdf" target="_blank" rel="noopener noreferrer">Télécharger L'enchainement pour le dos</a></button>
            </div>
            <Footer />
        </div>
    );
};

export default Enchainements;